.recommendations-wrapper {
  .publishers {
    .list {
      margin: 0;
      display: block;

      .item {
        margin: 0 16px 0 0;
        padding: 0;
        min-width: 440px;
        max-width: 440px;
        min-height: 234px;
        max-height: 234px;
      }

      .card {
        min-height: 234px;
      }
    }
  }
  .slick-list {
    mask-image: linear-gradient(to left, transparent 0, white 20px);
  }
}

.page-signup {
  display: flex;
  height: 100vh;
  background: #ffffff;
}
.form-signup .signup-steps .type .goals {
  margin-bottom: 0;
  display: block;
  color: #131315;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  white-space: nowrap;
  -webkit-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.reset-password-confirm-img {
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
  content: url("/assets/images/check-circle-green.svg");
}

.form-signup-theme-img {
  width: 500px;
  height: 456px;
  content: url("/assets/images/signup.svg");
}

.check-circle-green-img {
  width: 120px;
  height: 120px;
  content: url("/assets/images/check-circle-green.svg");
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error,
.parsley-error {
  border-color: #fc5449 !important;
}

.popup {
  min-width: 300px;
  max-width: 480px;
  padding: 54px 24px 30px;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
  margin: 0 auto;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  position: relative;
}

.popup .title {
  margin: 0 0 12px;
  font-size: 25px;
  line-height: 30px;
  font-weight: 500;
  color: #0e1952;
}

.popup .subtitle {
  max-width: 280px;
  margin-right: auto;
  margin-left: auto;
}

.popup .form-group {
  position: relative;
}

.popup .form-submit {
  margin-top: 24px;
}

.popup .form-submit .btn {
  margin-top: 0;
}

.popup .btn {
  width: 100%;
  max-width: 100%;
  margin-top: 30px;
  display: block;
}

.parsley-errors-list {
  margin: 2px 0 3px;
  padding: 0;
  list-style-type: none;
  font-size: 0.9em;
  line-height: 0.9em;
  opacity: 0;
  transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
}

.parsley-errors-list.filled {
  opacity: 1;
}

.parsley-required {
  position: absolute;
  color: #fc5449;
  font-size: 14px;
  display: block;
  text-align: left;
  margin-bottom: -10px;
}

.devise-error {
  text-align: center;
  text-transform: capitalize;
  padding-bottom: 20px;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

@-ms-viewport {
  width: device-width;
}

::-ms-reveal {
  display: none;
}

*,
* :before,
* :after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  width: 100%;
  height: 100%;
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: scrollbar;
}

html.nav-visible,
html.filter-visible {
  overflow: hidden;
}

body {
  min-height: 100%;
  min-width: 320px;
  position: relative;
  font: 18px/1.44em Roboto, sans-serif;
  color: #5d6d82;
}

a {
  color: #0e63f4;
  text-decoration: none;
  -webkit-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  outline: none;
}

a:hover,
a:focus {
  color: #0845ae;
}

p,
ul {
  margin: 0 0 20px;
}

ul {
  padding: 0 0 0 16px;
}

h1 {
  font-family: geomanist, sans-serif;
  font-weight: 500;
  line-height: normal;
  color: #0e1952;
}

h1 + p {
  margin-top: 0;
}

h1 {
  font-size: 40px;
  line-height: 1.25em;
}

.heading {
  color: #0e1952;
}

.btn {
  max-width: 280px;
  margin: 0 auto;
  padding: 12px 16px 10px;
  background: #0e63f4;
  border: 1px solid #0e63f4;
  border-radius: 4px;
  -webkit-box-shadow: 0 7px 16px rgba(18, 86, 236, 0.18);
  box-shadow: 0 7px 16px rgba(18, 86, 236, 0.18);
  color: #fff;
  display: block;
  font: 500 18px/1.45em "Geomanist", sans-serif;
  text-align: center;
  text-decoration: none;
  -webkit-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none !important;
  -webkit-appearance: none;
  white-space: nowrap;
}

.btn:focus {
  color: #fff;
  background: #0e63f4;
}

.btn:hover,
.btn:active {
  background-color: #124fd5;
  -webkit-box-shadow: 0 7px 16px rgba(18, 86, 236, 0.25);
  box-shadow: 0 7px 16px rgba(18, 86, 236, 0.25);
  color: #fff;
  outline: none;
  text-decoration: none;
}

.btn:active {
  position: relative;
  top: 1px;
}

.form-input {
  position: relative;
  width: 100%;
  padding: 18px 25px 15px;
  background: #ecf2fe;
  border: 1px solid #ecf2fe;
  font: 16px/20px "Geomanist", sans-serif;
  color: #0e1952;
  outline: none;
  display: block;
  border-radius: 3px;
}

.form-input__outer-label {
  padding: 16px 25px;
}

.form-input::-webkit-input-placeholder {
  color: #687991;
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.form-input::-moz-placeholder {
  color: #687991;
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.form-input:-ms-input-placeholder {
  color: #687991;
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.form-input:-moz-placeholder {
  color: #687991;
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.form-input:focus {
  outline: none;
}

.form-group--error .form-input {
  border-color: #fc5449;
}

.form-group {
  margin-bottom: 20px;
}

.form-group:after {
  content: "";
  display: table;
  clear: both;
}

.form-group-half {
  margin-bottom: 20px;
}

.form-group-half:last-child {
  margin-bottom: 0;
}

.error,
.parsley-type,
.parsley-minlength {
  color: #fc5449;
  font-size: 14px;
  display: block;
  text-align: left;
  margin-bottom: -10px;
}

.form-group + .error {
  margin-top: -10px;
}

body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.page {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  padding-top: 80px;
  padding-bottom: 80px;
  overflow-x: hidden;
}

.page-nobottom {
  padding-bottom: 0;
}

.container {
  padding: 0 24px;
}

.container:after {
  content: "";
  display: table;
  clear: both;
}

.header {
  position: fixed;
  left: 24px;
  top: 26px;
  z-index: 100;
}

.header .container {
  padding: 0;
}

.header .brand {
  width: 74px;
  height: 26px;
  margin: 0;
  display: block;
  overflow: hidden;
  text-indent: -1000px;
  white-space: nowrap;
  background: url("/assets/images/logo.png") no-repeat center center;
  background-size: contain;
}

.page-signup .header .brand {
  margin: 0 auto;
}

.header .brand a {
  width: 100%;
  height: 100%;
  text-indent: -1000px;
  overflow: hidden;
  display: block;
  text-decoration: none;
}

.form-signup {
  min-height: 100vh;
  padding: 80px 0;
  margin: -80px 0;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.form-signup:before {
  margin-right: 40px;
  display: none;
  content: "";
  background: #f8faff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  z-index: -1;
}

.form-signup .space {
  width: 100%;
}

.form-signup .image {
  display: none;
}

.form-signup .image img {
  width: 100%;
  height: auto;
  display: block;
}

.form-signup .heading {
  margin: 0 0 12px;
  font-size: 25px;
  line-height: 30px;
  font-weight: 500;
  color: #0e1952;

  &.heading--truncate {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.form-signup .subheading {
  font-size: 16px;
  line-height: 26px;
}

.form-signup .subheading .link-button {
  vertical-align: baseline;
}

.sso-buttons {
  margin-top: 18px;
}

.sso-buttons {
  p.subheading {
    margin-bottom: 10px;
  }
}

.form-signup .signup-steps {
  max-width: 488px;
  margin: 0 auto;
  padding: 0 24px;
}

.form-signup .signup-steps .step {
  display: none;
}

.form-signup .signup-steps .step.active {
  display: block;
}

.form-signup .signup-steps .note {
  padding: 0 10px;
  margin-top: 22px;
  font-size: 15px;
  line-height: 24px;
}

.form-signup .signup-steps .selectric,
.form-signup .signup-steps .selectric-items {
  text-align: left;
}

.form-signup .signup-steps .form-submit {
  margin-top: 24px;
}

.form-signup .signup-steps .form-submit .btn {
  width: 100%;
  max-width: none;
  margin: 0;
}

.form-signup .signup-steps .type {
  text-align: left;
}

.form-signup .signup-steps .type .form-group {
  margin-bottom: 16px;
}

.form-signup .signup-steps .type .form-radio,
.form-signup .signup-steps .type .form-checkbox,
.form-signup .signup-steps .type .form-other {
  padding: 26px 26px 18px;
  display: block;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.form-signup .signup-steps .type .form-radio .dot,
.form-signup .signup-steps .type .form-checkbox .dot,
.form-signup .signup-steps .type .form-other .dot {
  width: 100%;
  height: 100%;
  border: 1px solid #e8eaed;
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.form-signup .signup-steps .type .form-radio input[type="radio"]:checked ~ .dot,
.form-signup
  .signup-steps
  .type
  .form-radio
  input[type="checkbox"]:checked
  ~ .dot,
.form-signup
  .signup-steps
  .type
  .form-checkbox
  input[type="radio"]:checked
  ~ .dot,
.form-signup
  .signup-steps
  .type
  .form-checkbox
  input[type="checkbox"]:checked
  ~ .dot,
.form-signup .signup-steps .type .form-other input[type="radio"]:checked ~ .dot,
.form-signup
  .signup-steps
  .type
  .form-other
  input[type="checkbox"]:checked
  ~ .dot {
  border-color: #0e63f4;
  -webkit-box-shadow: 0 7px 14px 0 rgba(18, 86, 236, 0.06);
  box-shadow: 0 7px 14px 0 rgba(18, 86, 236, 0.06);
}

.form-signup
  .signup-steps
  .type
  .form-radio
  input[type="radio"]:checked
  ~ .dot:before,
.form-signup
  .signup-steps
  .type
  .form-radio
  input[type="checkbox"]:checked
  ~ .dot:before,
.form-signup
  .signup-steps
  .type
  .form-checkbox
  input[type="radio"]:checked
  ~ .dot:before,
.form-signup
  .signup-steps
  .type
  .form-checkbox
  input[type="checkbox"]:checked
  ~ .dot:before,
.form-signup
  .signup-steps
  .type
  .form-other
  input[type="radio"]:checked
  ~ .dot:before,
.form-signup
  .signup-steps
  .type
  .form-other
  input[type="checkbox"]:checked
  ~ .dot:before {
  opacity: 0;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.form-signup
  .signup-steps
  .type
  .form-radio
  input[type="radio"]:checked
  ~ .name,
.form-signup
  .signup-steps
  .type
  .form-radio
  input[type="checkbox"]:checked
  ~ .name,
.form-signup
  .signup-steps
  .type
  .form-checkbox
  input[type="radio"]:checked
  ~ .name,
.form-signup
  .signup-steps
  .type
  .form-checkbox
  input[type="checkbox"]:checked
  ~ .name,
.form-signup
  .signup-steps
  .type
  .form-other
  input[type="radio"]:checked
  ~ .name,
.form-signup
  .signup-steps
  .type
  .form-other
  input[type="checkbox"]:checked
  ~ .name {
  color: #0e63f4;
}

.form-signup .signup-steps .type .form-other {
  padding-bottom: 20px;
  cursor: default;
}

.form-signup .signup-steps .type textarea {
  height: 100px;
}

.form-signup .signup-steps .type .icon {
  width: 40px;
  height: 40px;
  display: block;
  margin-bottom: 20px;
}

.form-signup .signup-steps .type .name {
  margin-bottom: 10px;
  display: block;
  color: #0e1952;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  white-space: nowrap;
  -webkit-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.form-signup .signup-steps .type-audience .form-radio,
.form-signup .signup-steps .type-audience .form-checkbox {
  padding-top: 20px;
  padding-bottom: 26px;
}

.form-signup .signup-steps .type-audience .form-radio .name,
.form-signup .signup-steps .type-audience .form-checkbox .name {
  margin-bottom: 0;
}

.form-checkbox,
.form-radio {
  padding-left: 24px;
  position: relative;
  overflow: hidden;
  display: block;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.form-checkbox input[type="checkbox"],
.form-checkbox input[type="radio"],
.form-radio input[type="checkbox"],
.form-radio input[type="radio"] {
  position: absolute;
  top: -100px;
  left: -100px;
}

.form-signup .signup-steps .type .form-radio,
.form-signup .signup-steps .type .form-checkbox,
.form-signup .signup-steps .type .form-other {
  padding: 26px 26px 18px;
  display: block;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.form-signup .signup-steps .type .goals {
  margin-bottom: 0px;
  display: block;
  color: #131315;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  white-space: nowrap;
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

label {
  &.input-caption {
    color: #131315;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 400;
  }
}

.signup-input-group {
  text-align: left;
}

.form-checkbox .dot,
.form-radio .dot {
  width: 16px;
  height: 16px;
  border: 1px solid rgba(93, 109, 130, 0.5);
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 2px;
  background: white;
}

.form-signup .signup-steps .type .form-radio .dot,
.form-signup .signup-steps .type .form-checkbox .dot,
.form-signup .signup-steps .type .form-other .dot {
  width: 100%;
  height: 100%;
  border: 1px solid #e8eaed;
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.form-signup .signup-steps .type .form-checkbox.inited {
  padding: 22px 26px;
}

.floater {
  display: block;
  position: relative;
}

.floater label,
.floater > span {
  position: absolute;
  left: 25px;
  top: 12px;
  cursor: text;
  opacity: 0.7;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 14px;
  line-height: 1;
  color: #5d6d82;
  pointer-events: none;
}

.floater select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.floater textarea,
.floater input {
  font-size: inherit;
  padding-top: 1em;
  margin-bottom: 2px;
  padding-top: 26px;
  padding-bottom: 7px;

  &[name="password"] {
    padding-right: 40px;
  }
}

.floater textarea::-webkit-input-placeholder,
.floater input::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.floater textarea:-ms-input-placeholder,
.floater input:-ms-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.floater textarea::-ms-input-placeholder,
.floater input::-ms-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.floater textarea::placeholder,
.floater input::placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.floater textarea:placeholder-shown:not(:focus)::-webkit-input-placeholder,
.floater input:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

.floater textarea:placeholder-shown:not(:focus):-ms-input-placeholder,
.floater input:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}

.floater textarea:placeholder-shown:not(:focus)::-ms-input-placeholder,
.floater input:placeholder-shown:not(:focus)::-ms-input-placeholder {
  opacity: 0;
}

.floater textarea:placeholder-shown:not(:focus)::placeholder,
.floater input:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}

.floater textarea:placeholder-shown:not(:focus) + *,
.floater input:placeholder-shown:not(:focus) + * {
  font-size: 16px;
  opacity: 1;
  top: 20px;
}

.floater textarea::-webkit-input-placeholder,
.floater input::-webkit-input-placeholder {
  opacity: 0;
}

.floater textarea::-moz-placeholder,
.floater input::-moz-placeholder {
  opacity: 0;
}

.floater textarea:-ms-input-placeholder,
.floater input:-ms-input-placeholder {
  opacity: 0;
}

.floater textarea:-moz-placeholder,
.floater input:-moz-placeholder {
  opacity: 0;
}

.floater textarea:focus,
.floater input:focus {
  outline: none;
}

@media (min-width: 750px) {
  h1 {
    font-size: 45px;
    line-height: 1.22em;
  }

  .btn {
    max-width: none;
    margin-right: 0;
    margin-left: 0;
    display: inline-block;
    vertical-align: middle;
  }

  .page {
    padding-bottom: 90px;
  }

  .page-nobottom {
    padding-bottom: 0;
  }

  .container {
    max-width: 710px;
    padding: 0 20px;
    margin: 0 auto;
  }

  .header {
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .header .brand {
    position: absolute;
    left: 40px;
    top: 28px;
  }

  .form-signup .heading {
    margin-bottom: 28px;
    font-size: 28px;
    line-height: 34px;
  }

  .form-signup .heading + .subheading {
    margin-top: -20px;
  }

  .form-signup .subheading {
    margin-bottom: 24px;
  }

  .form-signup .signup-steps .step-4 .heading {
    margin-bottom: 28px;
  }

  .form-group-half {
    width: 50%;
    margin-bottom: 0;
    float: left;
  }

  .form-group-half:nth-child(1),
  .form-group-half:nth-child(2n + 1) {
    padding-right: 10px;
  }

  .form-group-half:nth-child(2n) {
    padding-left: 10px;
  }

  .floater.form-group-half:not(:first-child) label {
    left: 35px;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 50px;
  }

  .page {
    padding-bottom: 110px;
    overflow-x: visible;
  }

  .page-nobottom {
    padding-bottom: 0;
  }

  .container {
    max-width: 1170px;
  }

  .header .container {
    height: 100%;
    position: relative;
  }

  .header .brand {
    left: 22px;
  }

  .form-signup:before {
    display: block;
  }

  .form-signup .space {
    max-width: 930px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .form-signup .image {
    width: 380px;
    margin: 0;
    display: block;
    position: relative;
    top: -20px;
  }

  .form-signup .signup-steps {
    width: 456px;
    max-width: none;
    padding: 0;
    margin: 0;
  }
}

@media (min-width: 1440px) {
  h1 {
    font-size: 56px;
    line-height: 1.196em;
  }

  .page {
    padding-bottom: 120px;
  }

  .page-nobottom {
    padding-bottom: 0;
  }

  .container {
    max-width: 1170px;
  }

  .header .brand {
    left: 24px;
    top: 28px;
  }

  .form-signup:before {
    margin-right: 16px;
  }

  .form-signup .space {
    max-width: 1180px;
    margin: 0 auto;
    position: relative;
    left: -28px;
  }

  .form-signup .image {
    width: 500px;
    padding: 0;
    top: -22px;
  }

  .form-signup .signup-steps {
    width: 450px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1230px;
  }
}

@media (max-width: 480px) {
  .error {
    text-align: center;
  }
}

@media (max-width: 667px) {
  .page-signup .header {
    position: absolute;
    right: 24px;
  }
}

.PhoneInputInput {
  padding-left: 22px;
  outline: none;
  border: none;
  background: transparent;
  font-size: 16px;

  &:focus {
    outline: none;
    border: none;
  }
}

.password__helper {
  padding: 6px 16px;
  margin-bottom: 4px;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
}

.password__helper-item {
  display: flex;
  align-items: center;
  padding-left: 14px;
  position: relative;
}

.password__helper .circle {
  position: absolute;
  left: 2px;
  top: 7px;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #4f4f4f;
}

.password__helper .error {
  position: absolute;
  left: -2px;
  top: 2px;
  width: 8px;
  height: 8px;
  margin: 0;
}

.password__helper .check {
  position: absolute;
  left: 0;
  top: 5px;
  display: block;
  width: 8px;
  height: 4px;
  border-left: 1px solid #27ae60;
  border-bottom: 1px solid #27ae60;
  transform: rotate(-45deg);
}

.profile-search-wrapper {
  .publishers  {
    margin: 0;

    .list {
      margin: 0;
      display: block;

      .item {
        margin: 0 0 8px 0;
        padding: 0;
        max-width: 100%;
      }

      .card {
        min-height: 218px;
        //max-height: 218px;
      }
    }
  }
}

#edit,
#edit_dedicated {
  color: #467fcf;
  text-decoration: underline;
  cursor: pointer;
}

.submit-schedule {
  display: none;
}

.event-open {
  background: rgba(47, 179, 68, 0.08) !important;
  border: 1px solid #2aa13d !important;
  cursor: pointer;
}

.event-open .fc-event-title {
  color: #2aa13d !important;
}

.fc-day .event-reserved {
  background: rgba(214, 57, 57, 0.08) !important;
  border: 1px solid #c13333 !important;
  cursor: pointer;
}

.fc-day .event-reserved .fc-event-title {
  color: #c13333 !important;
  text-decoration: line-through !important;
}

.fc-day .dedicated-email {
  background: rgba(47, 78, 179, 0.08) !important;
  border: 1px solid #0e63f4 !important;
  cursor: pointer;
}

.fc-day .dedicated-email .fc-event-title {
  color: #0e63f4 !important;
}

.fc-bg-event,
.fc-event-today {
  opacity: unset !important;
}

.fc-daygrid-day:has(.fc-bg-event) {
  .fc-daygrid-day-top {
    opacity: 1 !important;
  }
}

.fc .fc-daygrid-day.fc-day-today {
  background: unset !important;
}

.fc-bg-event .fc-daygrid-day-top,
.fc-event-today .fc-daygrid-day-top {
  opacity: 0 !important;
}

.fc .fc-day .fc-daygrid-day-top {
  opacity: 0.3;
}

.full-opacity {
  opacity: 1 !important;
}

.event-icon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  padding-right: 4px;
}

.fc-event-title {
  font-size: 0.8rem;
  line-height: 0.85rem;
  white-space: normal;
}

.fc-col-header-cell-cushion {
  color: inherit;
  text-decoration: none;
}

.fc-daygrid-day-number {
  color: inherit;
}

.fc .fc-day-disabled {
  background-color: #f8faff;
}

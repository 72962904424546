.CalendarDay__selected_span {
  background: #add8e6;
  background: #7daaf9;
  color: #0e1952;
  border: 1px solid white;

  &:hover {
    background: #040273;
    background: #1a56db;
    color: white;
    border: 1px double white;
  }
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #7daaf9;
  color: #0e1952;
  border: 1px double white;
}

.CalendarDay__default {
  border: 1px solid white;

  &:hover {
    background: #7daaf9;
  }
}
.DayPickerNavigation_button__default {
  border-color: white;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right-color: #1a56db;
}

.CalendarMonth_caption {
  font-size: 12px;
}
.DayPickerNavigation_svg__horizontal {
  fill: #111928;
  width: 20px;
}
.DateInput_input__focused {
  border-bottom-color: #1a56db;
}

.DateInput__small {
  width: 90px;
}

.DateRangePickerInput__withBorder {
  border-radius: 8px;
}

.DateRangePickerInput_calendarIcon {
  margin: 0;
  padding-top: 3px;
  padding-left: 11px;
  padding-right: 4px;
}
.DateRangePickerInput__showClearDates {
  padding-right: 23px;
}
.DateRangePickerInput_clearDates__small {
  padding: 0;
  width: 30px;
  height: 30px;
}
.DateRangePickerInput_clearDates_svg__small {
  position: absolute;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}
.DateRangePickerInput_clearDates__small {
  margin-right: 3px;
}

.CalendarDay__selected {
  background: #1a56db;
  border: 1px solid white;
  color: white;

  &:hover {
    background: #1a56db;
    color: white;
    border: 1px double white;
  }
}
.CalendarDay__blocked_out_of_range:hover {
  background: white;
  border: none;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #fff;
  color: #cacccd;
  border: 1px solid white;
}

.DateRangePicker_picker__portal {
  z-index: 10000;
}

/* ==========================================================================
   FILTER
   ========================================================================== */
.filter {
  padding: 135px 0px 24px 0px;
  border: none;
  background: white;
  display: none;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 90;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.filter-visible .filter {
  display: block;
}
.filter-done .filter {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.filter .heading {
  display: block;
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: #0e1952;
  position: absolute;
  left: 24px;
  top: 87px;
}
.filter > fieldset {
  height: 100%;
  display: block;
  padding: 0;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.filter-space {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-left: 24px;
  padding-right: 24px;
}
.filter-space::after {
  content: "";
  display: table;
  clear: both;
}
.filter .form-group {
  margin-bottom: 30px;
}
.filter .form-search {
  position: relative;
}
.filter .form-search .form-input {
  padding: 10px 10px 10px 44px;
  background: white
    url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE3LjcxMSAxNi4zMTFhLjk4Ni45ODYgMCAwIDEtLjAwMyAxLjM5OC45ODguOTg4IDAgMCAxLTEuMzk4LjAwM2wtNC4zNzYtNC4zNzdhNy40MjggNy40MjggMCAxIDEgMS40LTEuNGw0LjM3NyA0LjM3NnpNNy40MjggMTIuODc2YTUuNDQ3IDUuNDQ3IDAgMSAwIDAtMTAuODk1IDUuNDQ3IDUuNDQ3IDAgMCAwIDAgMTAuODk1eiIgZmlsbD0iIzYyNzI4MyIgZmlsbC1ydWxlPSJldmVub2RkIiBmaWxsLW9wYWNpdHk9Ii40Ii8+PC9zdmc+")
    14px 50% no-repeat;
  background-size: 18px 18px;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 4px;
  font-size: 15px;
}
.filter .form-search .form-input::-webkit-input-placeholder {
  color: rgba(93, 109, 130, 0.5);
}
.filter .form-search .form-input::-moz-placeholder {
  color: rgba(93, 109, 130, 0.5);
}
.filter .form-search .form-input:-ms-input-placeholder {
  color: rgba(93, 109, 130, 0.5);
}
.filter .form-search .form-input:-moz-placeholder {
  color: rgba(93, 109, 130, 0.5);
}
.filter .form-search .btn {
  width: 44px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}
.filter .title {
  margin-bottom: 14px;
  display: block;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: rgba(93, 109, 130, 0.5);
  text-transform: uppercase;
  letter-spacing: 0.08em;
}
.filter .title + br {
  display: none;
}
.filter .line {
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}
.filter .form-checkbox {
  padding-right: 15px;
  display: block;
  color: #0e1952;
  font-size: 15px;
  line-height: 24px;
  font-weight: normal;
}
.filter .amount {
  font-size: 15px;
  line-height: 24px;
  color: rgba(93, 109, 130, 0.5);
  cursor: default;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: -1;
}
.filter .more {
  margin-left: 24px;
  border-bottom: 1px dashed rgba(93, 109, 130, 0.5);
  display: inline-block;
  vertical-align: top;
  font-size: 15px;
  line-height: 20px;
  color: rgba(93, 109, 130, 0.5);
  -webkit-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    border-bottom-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    border-bottom-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    border-bottom-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.filter .more:hover,
.filter .more:focus {
  color: #0e63f4;
  border-bottom-color: #0e63f4;
}
.filter-open {
  margin: 20px 0 40px;
  display: block;
  padding: 12px 24px 8px;
  border: 1px solid rgba(93, 109, 130, 0.4);
  border-radius: 3px;
  color: #5d6d82;
  font-size: 15px;
  line-height: 20px;
  position: relative;
}
.filter-open:hover,
.filter-open:focus {
  color: #5d6d82;
}
.filter-open::before {
  width: 10px;
  height: 10px;
  margin: auto;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjZweCIgdmlld0JveD0iMCAwIDEwIDYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+UmVjdGFuZ2xlIDEwPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJIZWFkZXIiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtb3BhY2l0eT0iMC45Ij4gICAgICAgIDxnIGlkPSJIZWFkZXJfXzEwMjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC05NjUuMDAwMDAwLCAtMzguMDAwMDAwKSIgZmlsbD0iIzEyNTZlYyIgZmlsbC1ydWxlPSJub256ZXJvIj4gICAgICAgICAgICA8ZyBpZD0iSW50cm8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYxNS4wMDAwMDAsIDAuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxnIGlkPSJIZWFkZXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDg4LjAwMDAwMCwgMjYuMDAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iSGVhZGVyLUxvZ2dlZC1JbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDEuMDAwMDAwLCAwLjAwMDAwMCkiPiAgICAgICAgICAgICAgICAgICAgICAgIDxnIGlkPSJHcm91cC0yIj4gICAgICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTIyNC4wMDUzMDMsMTEuMDA1MzAzIEwyMjQuMDA1MzAzLDE1LjY3MTk2OTcgQzIyNC4wMDUzMDMsMTYuMTAxNTI0NSAyMjMuNjU3MDgsMTYuNDQ5NzQ3NSAyMjMuMjI3NTI1LDE2LjQ0OTc0NzUgQzIyMi43OTc5NywxNi40NDk3NDc1IDIyMi40NDk3NDcsMTYuMTAxNTI0NSAyMjIuNDQ5NzQ3LDE1LjY3MTk2OTcgTDIyMi40NDk3NDcsOS40NDk3NDc0NyBMMjI4LjY3MTk3LDkuNDQ5NzQ3NDcgQzIyOS4xMDE1MjQsOS40NDk3NDc0NyAyMjkuNDQ5NzQ3LDkuNzk3OTcwNDQgMjI5LjQ0OTc0NywxMC4yMjc1MjUyIEMyMjkuNDQ5NzQ3LDEwLjY1NzA4MDEgMjI5LjEwMTUyNCwxMS4wMDUzMDMgMjI4LjY3MTk3LDExLjAwNTMwMyBMMjI0LjAwNTMwMywxMS4wMDUzMDMgWiIgaWQ9IlJlY3RhbmdsZS0xMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjI1Ljk0OTc0NywgMTIuOTQ5NzQ3KSBzY2FsZSgxLCAtMSkgcm90YXRlKDQ1LjAwMDAwMCkgdHJhbnNsYXRlKC0yMjUuOTQ5NzQ3LCAtMTIuOTQ5NzQ3KSAiPjwvcGF0aD4gICAgICAgICAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=")
    center center no-repeat;
  background-size: contain;
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  right: 20px;
  -webkit-transform-origin: 5px 50%;
  -ms-transform-origin: 5px 50%;
  transform-origin: 5px 50%;
  -webkit-transition: -webkit-transform 0.2s
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.filter-open.active::before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.filter-close {
  width: 20px;
  height: 20px;
  display: block;
  opacity: 0.3;
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  top: 90px;
  right: 25px;
}
.filter-close::before,
.filter-close::after {
  width: 20px;
  height: 2px;
  margin: auto;
  background: #5d6d82;
  border-radius: 2px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.filter-close::before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.filter-close::after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.filter-close:hover,
.filter-close:focus {
  opacity: 0.7;
}

.sort-by {
  display: none;

  &--mobile {
    display: block;
  }
}

@media (min-width: 1024px) {
  .catalog .filter {
    padding: 0;
    margin: 0 96px 40px 0;
    background: transparent;
    display: block;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    position: static;
    opacity: 1;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    width: 220px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-left: 0;
  }
  .filter .heading {
    display: none;
  }
  .filter-space {
    height: auto;
    overflow: visible;
    padding: 0;
  }

  .filter .form-group {
    width: 260px;
    margin-bottom: 32px;
  }

  .filter-open {
    display: none;
  }
  .filter-close {
    display: none;
  }

  .sort-by {
    display: block;

    &--mobile {
      display: none;
    }
  }
}

@media (min-width: 1440px) {
  .catalog .filter {
    margin-right: 70px;
  }
}

@media (min-width: 1600px) {
  .catalog .filter {
    margin-right: 86px;
  }
}

html.filter-visible {
  overflow: hidden;
}

.react-calendar {
  width: 100%;
  border: 0 solid #e5e7eb;
  margin-bottom: 10px;
}

.react-calendar__tile {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  aspect-ratio: 1 / 1;
  height: auto;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  &:disabled {
    background-color: transparent;
    color: #b6b9bc;
    cursor: default;
    &:hover {
      background-color: transparent;
    }
    &.outdated {
      background-color: #fceff0;
      color: #dc3545;
    }
  }

  &:hover,
  &:focus,
  &:enabled:hover,
  &:enabled:focus {
    background-color: #f0f5fe;
  }

  & > div,
  & > abbr {
    background-color: transparent !important;
    & > span {
      background-color: transparent !important;
    }
  }

  &.not_available abbr {
    text-decoration-line: line-through;
  }

  &--active {
    background-color: transparent;
    color: #000;
  }

  &--hasActive,
  &--active,
  &.selected {
    &.selected {
      background-color: #4586f6;
      color: #fff;

      &:hover,
      &:focus {
        background-color: rgb(37 99 235);
      }

      &.not_available {
        background-color: #fceff0;
        color: #dc3545;
        &:hover {
          background-color: #fbe7e9;
        }
      }

      &:has(.price-by-date__smart) {
        background-color: #28a745;
        &:hover,
        &:focus {
          background-color: #208537;
        }
      }

      .price-by-date {
        color: #fff;
      }
    }
  }
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next-button,
.react-calendar__navigation__prev-button {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.625rem;

  :hover {
    background-color: #fff;
  }
}

.react-calendar__navigation__next-button:disabled,
.react-calendar__navigation__prev-button:disabled {
  color: #ccc;

  :hover {
    background-color: transparent;
  }
}

.react-calendar__navigation__next-button:disabled .MuiSvgIcon-root,
.react-calendar__navigation__prev-button:disabled .MuiSvgIcon-root {
  fill: #d4d4d4;
}

.react-calendar__month-view__days__day {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 1rem;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #111827;

  :hover {
    background-color: inherit;
  }
}

.react-calendar__date-item {
  flex-direction: column;

  .price-by-date {
    font-size: 12px;

    &__smart {
      color: rgb(5, 150, 105);
    }
  }
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar__navigation {
  margin-bottom: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    &:disabled {
      background-color: initial;
    }

    &:enabled:hover,
    &:enabled:focus {
      background-color: initial;
    }
  }
}

.react-calendar__navigation__label {
  border-radius: 0.375rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #131315;
  text-transform: none;
}

.react-calendar__month-view__weekdays {
  margin-bottom: 1.75rem;
  display: flex;
  justify-content: center;
}

.react-calendar__month-view__weekdays__weekday {
  height: 1.5rem;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.price-label--regular {
  display: none;
}

@media (min-width: 640px) {
  .price-label {
    &--hidden-mobile {
      display: none;
    }
    &--regular {
      display: inline;
    }
  }
}
